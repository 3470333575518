<template>
    <div class="home">
        <div class="inte"></div>
        <main class="el_table" style="margin: 15px 15px 0">
            <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px"
                    border>
                <el-table-column
                        prop="title"
                        label="文章标题"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="menuName"
                        label="所在栏目"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="文章状态"
                        align="center"
                        width="200">
                       <template slot-scope="scope">
                            <span v-if="scope.row.status==0">有效</span>
                            <span v-if="scope.row.status==1">关闭</span>
                        </template>
                </el-table-column>
                <el-table-column
                        prop="publishTime" 
                        label="发布日期"
                        align="center" 
                        width="200">
                </el-table-column>
                <el-table-column label="操作"  align="center" width="200">
                    <template slot-scope="scope">
                        <el-button
                                type="danger"
                                size="medium"
                                @click="editor(scope.$index, scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center; height:60px;">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10,20, 30, 40, 50]"
                        :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="connt">
                </el-pagination>
            </div>


        </main>
    </div>
</template>

<script>
    export default {
        name: "Web_SingleMenuArt",
        data(){
            return{
              tabIndex:0,
              ShowitemList:{
                  item:[]
              },
              queryform:{
                  page:1,
                  limit:10,
              },
              newTitleType:[],    
              tableData:[],
              connt:null
            }
        },
        created() {
            //this.getNewType(2)
            this.getNewzwData()
        },
        methods:{
             tabNavtilte:function(item,index){
                 this.tabIndex=index;
                 this.queryform.menid=item.id;
                 this.getNewzwData();
             },
             searchName:function(){
                if(this.Util.isBlank(this.queryform.keywords)){
                  this.$message.error('请输入关键字！');
                  return; 
               }
                this.getNewzwData()
             },
             //查询
             searchNewList:function(){
                 console.log(this.queryform)
                 this.getNewzwData()
             },
             //时间选择
             changePublishTime:function(eArry){
                 if(eArry){
                    this.queryform.publicTimeStart=eArry[0];
                    this.queryform.publicTimeEnd=eArry[1];
                 }else{
                    this.queryform.publicTimeStart='';
                    this.queryform.publicTimeEnd='';
                 }
             },
              getNewType:function(type){
                var _this=this;
                axios.get(this.API.news.menus,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        iclass:type
                    }
                }).then(function (res) {
                    if(res.data.success&&res.data.data.length>0){
                        _this.newTitleType=res.data.data;
                        if(_this.newTitleType.length>0){
                            _this.queryform.menid=_this.newTitleType[0].id;
                            _this.getNewzwData();
                        }
                        console.log('分类',res);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            getNewzwData:function(){
               var _this=this;
                axios.get(this.API.news.singleMenuArtList,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:_this.queryform
                }).then(function (res) {
                     console.log('列表',res);
                    if(res.data.success){
                       _this.tableData=res.data.data.list;
                       _this.connt=res.data.data.pager.recordCount;
                    }else{

                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
             getWebNewadd:function(){
                this.$parent.pullItem("添加新闻信息","Web_newAdd",{type:2}) 
             },
             //改变状态
             handleStatusTab:function(index,row){
                var _this=this;
                if(row.status==0){
                    row.status=1
                }else{
                    row.status=0
                }
                axios.get(this.API.news.status,{
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params:{
                        id:row.id,
                        status:row.status
                    }
                }).then(function (res) {
                    if(res.data.success){
                       _this.$message.success('修改成功');
                    }else{
                        if(row.status==0){
                           row.status==1;
                       }else{
                           row.status==0;
                       }
                    }
                })
             },
             //编辑
            editor:function(index,row){
                row.type=3;
                this.$parent.pullItem("编辑单栏目文章","Web_smaEditor",row)
             },

             //分页操作
             handleSizeChange:function(e){
                this.queryform.limit=e;
                this.getNewzwData();
             },
             handleCurrentChange:function(e){
                this.queryform.page=e;
                this.getNewzwData();   
             }


        }
    }
</script>

<style scoped lang="scss">
.isDivcom{
    color:#000;
    border-bottom: 2px solid #000000;
}
.inte{ 
    height: 10px;
}
</style>